body{
  background-color: #ACA8C6;
}

.App {
  text-align: left;
  background-color: #F6F5FC;
  border-top: 3px solid #E2D9DF;
  max-width: 1200px;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.App-header {
  padding-top: 25px;
  padding-left: 25px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.App-header img {
  margin-bottom: 10px;
  width: 80px;
}

.App-header h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 36px;
  margin: 0;
  padding-left: 20px;
  color: #583A73;
}


.Image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 80vh;
  margin-top: 20px;
  max-height: 600px;
  background-image: url('../img/background.png');
  background-size: cover;
  background-position: center;
}
.Signup-box {
  background-color: white;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 15px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.8);
  text-align: center;
  width: 300px;
  color: #583A73;
}

.Email-input,
.Submit-button {
  margin-top: 10px;
  border-radius: 15px;
  border: 1px solid lightgray;
  padding: 10px;
  width: 90%;
  box-sizing: border-box;
}

.Submit-button {
  cursor: pointer;
  background-color: #45318A;
  color: white;
  border: 1px solid lightgray;
}

.Background-image {
  position: relative;
}

.Dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.Button {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid lightgray;
  background-color: #45318A;
  color: white;
  cursor: pointer;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.New-section {
  text-align: center;
  padding: 20px;
}

.Section-header {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #583A73;
  font-weight: bold;
  font-size: 30px;
}

.Section-text {
  font-weight: lighter;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  max-width: 40%;
  color: #583A73;
  margin: 0 auto;
  padding-bottom: 40px;
}

.slick-slider {
  max-width: 600px;
  margin: 0 auto;
}

.slick-slide img {
  max-width: 100%;
  max-height: 800px;
  display: block;
  margin: 0 auto;
}

.Image-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.Image-modal img {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
}

.Close-modal {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.App-footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

.App-footer ul {
  list-style: none;
  padding: 0;
}

.App-footer li {
  display: inline;
  margin-right: 20px;
}

.App-footer a {
  color: white;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}

a.no-style {
  color: inherit;      /* This makes the link use the text color of its parent element */
  text-decoration: none; /* Removes underline from the link */
}

/* Optionally, you can also remove the different color for visited links */
a.no-style:visited {
  color: inherit;
}

/* If you also want to remove the link's 'click' effect (hover and active states) */
a.no-style:hover, a.no-style:active {
  color: inherit;
  text-decoration: none;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
}
h1, h2 {
  color: #583A73;
}
p, ul, li {
  margin: 10px 0;
}

.email-form-container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}